import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { ClickAwayListener, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import CustomActionButton from "./CustomActionButton";

const CellPopOverAction = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageInfo, setImageInfo] = useState(props?.node?.data);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap:5
      }}
    >
      <span>{props.getValue()}</span>
      {imageInfo?.show_icon==="Y"? (
        <ClickAwayListener onClickAway={(e) => setOpen(false)}>
          <ErrorIcon
            sx={{ color: props.color || "#FF832B" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement(placement);
              setOpen(!open);
            }}
            // onMouseLeave={()=> setOpen(!open)}
          />
        </ClickAwayListener>
      ) : null}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ width: 450, zIndex: 10 }} 
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ py: 1, px: 2, width: props.width || "max-content" }}>
              <Typography sx={{ fontWeight: 600, fontSize: 13 }}>
                {imageInfo?.popover_title}
              </Typography>
              <Typography sx={{ fontSize: 11 }}>
                {imageInfo?.popover_description}
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                {imageInfo.popover_action_path && (
                  <Button
                    sx={{ fontSize: 10, fontWeight: 600 }}
                    onClick={() =>
                      navigate(
                        "/" +
                          window?.location?.pathname?.split("/")[1] +
                          "/" +
                          window?.location?.pathname?.split("/")[2] +
                          imageInfo.popover_action_path
                      )
                    }
                  >
                    {imageInfo.buttonLabel}{" "}
                  </Button>
                )}
                {props.customButton && (
                  <CustomActionButton data={props.customButton} />
                )}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default CellPopOverAction;
