import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatNumber, cellValueToNumber } from "../components/Table/utils";
const fn = {
  "*": (a, b) => formatNumber(a * b),
};
export default function useAction() {
  const {
    gridRefs,
    tableInfo,
    dropdownSelectionData,
    selectedRowInfo,
    dependentInfo,
    formEditedValues,
    modalRefs,
    buffer,
    paramsRefs,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onAction(action, dynamicPayload, selectedValue) {
    let actionOccurred = true;
    switch (action?.actionName) {
      case "UPDATE_TABLES_DATA":
        {
          const payload = {};
          action.dest.forEach((key, index) => {
            payload[key] = tableInfo[action.src[index]];
          });
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        }
        break;
      case "ADD_ROWS_BY_TAG":
        {
          const src = tableInfo[action.src_table_key];
          const dest = tableInfo[action.dest_table_key];
          let rowsToAdd = null;
          const { tagInfo } = action;
          if (tagInfo?.tagSets) {
            const tagSets = {};
            Object.keys(tagInfo.tagSets).forEach(
              (key) =>
                (tagSets[key] = new Set(
                  tagInfo.srcFromBuffer
                    ? buffer[tagInfo.tagSets[key]]
                    : tagInfo.tagSets[key]
                ))
            );
            rowsToAdd = src?.filter((row) => {
              return tagInfo.columns.every((field) =>
                tagSets[field].has(row[field])
              );
            });
          } else {
            let tags = tagInfo?.tags;
            if (tagInfo?.filters) {
              tags = tagInfo.filters.map((filterId) => {
                const options = dropdownSelectionData[filterId];
                if (options && options.length > 0) return options[0].id;
                return null;
              });
            }
            rowsToAdd = src?.filter((row) => {
              return tagInfo.columns.every(
                (field, index) => row[field] === tags[index]
              );
            });
          }
          dispatch({
            type: "TABLE_DATA",
            payload: {
              [action.dest_table_key]: action.overrideData
                ? rowsToAdd
                : [...dest, ...rowsToAdd],
            },
          });
        }
        break;
      case "ADD_ROWS_BY_SELECTION": {
        const selectedRows = selectedRowInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: [...selectedRows] },
          });
        }
        break;
      }
      case "APPEND_ROWS_BY_SELECTION": {
        const selectedRows = selectedRowInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          const params = {};
          action.destTables.forEach((key) => {
            const table = tableInfo[key] || [];
            selectedRows.forEach(
              (row, index) => (row.id = table.length + 1 + index)
            );
            params[key] = [...table, ...selectedRows];
          });
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      }
      case "DELETE_ROWS": {
        let selectedRows = null;
        if (dynamicPayload && action?.payloadType) {
          if (action.payloadType === "row") {
            selectedRows = [dynamicPayload];
          }
        } else {
          selectedRows = selectedRowInfo[action.parent_table_key];
        }
        if (selectedRows && selectedRows.length > 0) {
          const params = {};
          const selectedRowIds = new Set(selectedRows.map((row) => row.id));
          action.tables.forEach((key) => {
            const table = tableInfo[key] || [];
            params[key] = table.filter((row) => !selectedRowIds.has(row.id));
          });
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      }
      case "DELETE_ROWS_BY_TAG":
        {
          const { tagInfo } = action;
          const tagSets = {};
          if (tagInfo?.tagSets) {
            tagInfo.columns.forEach(
              (field) => (tagSets[field] = new Set(tagInfo.tagSets[field]))
            );
          } else {
            tagInfo.columns.forEach((field) => (tagSets[field] = new Set()));
            const selectedRows = gridRefs[action.src_table_key].current.api
              .getSelectedNodes()
              ?.map((node) => node.data);
            if (selectedRows && selectedRows.length > 0) {
              selectedRows.forEach((row) =>
                tagInfo.columns.forEach((field) =>
                  tagSets[field].add(row[field])
                )
              );
            }
          }
          const rows = tableInfo[action.dest_table_key].filter((row) => {
            return !tagInfo.columns.every((field) =>
              tagSets[field].has(row[field])
            );
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: [...rows] },
          });
        }
        break;
      case "SET_ROWS_DATA": {
        const tableRows = tableInfo[action.parent_table_key];
        if (tableRows) {
          if (action?.limitToSelections) {
            if (action?.basedOnId) {
              let selectedRows = null;
              if (dynamicPayload) {
                selectedRows =
                  action.payloadType === "row"
                    ? [dynamicPayload.data["id"]]
                    : dynamicPayload;
              } else if (action?.source_table_key) {
                const rows = selectedRowInfo[action.source_table_key];
                if (rows && rows.length > 0) {
                  selectedRows = rows.map((row) => row["id"]);
                }
              } else {
                selectedRows = gridRefs[action.parent_table_key]?.current.api
                  .getSelectedNodes()
                  .map((node) => node.data["id"]);
              }
              if (selectedRows && selectedRows.length > 0) {
                const selectedIds = new Set(selectedRows);
                const rows = [...tableRows];
                rows.forEach((row) => {
                  const flag = action?.invertSelection
                    ? !selectedIds.has(row.id)
                    : selectedIds.has(row.id);
                  if (flag) {
                    action.data.forEach((obj) => {
                      let v = "";
                      if (dynamicPayload && action.payloadType === "row") {
                        if (obj?.compute) {
                          const [a, op, b] = obj.compute;
                          v = fn[op](
                            dynamicPayload.data[a],
                            dynamicPayload.data[b]
                          );
                        } else {
                          v = obj.value;
                        }
                      } else {
                        v = obj.value;
                      }
                      row[obj.field] = v;
                    });
                  }
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            } else {
              const selectedRows = gridRefs[
                action.parent_table_key
              ]?.current.api
                .getSelectedNodes()
                .map((node) => node.rowIndex);
              if (selectedRows && selectedRows.length > 0) {
                const rows = [...tableRows];
                selectedRows.forEach((rowIndex) => {
                  const row = rows[rowIndex];
                  action.data.forEach((obj) => (row[obj.field] = obj.value));
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            }
          } else {
            const rows = [...tableRows];
            rows.forEach((row) =>
              action.data.forEach((obj) => {
                if (obj?.bufferKey) {
                  row[obj.field] = buffer[obj.bufferKey];
                } else if (dynamicPayload) {
                  if (action.payloadType === "row") {
                    //dynamicPayload should be node in case of type row
                    row[obj.field] = dynamicPayload.data[obj.sourceField];
                  }
                } else {
                  row[obj.field] = obj.value;
                }
              })
            );
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: rows },
            });
          }
        }
        break;
      }
      case "BULK_EDIT_DROPDOWNS": {
        const selectedRows = [...selectedRowInfo[`${action.parent_table_key}`]];
        if (selectedRows && dropdownSelectionData) {
          let output = [];
          if (action?.source_dropdowns && dropdownSelectionData) {
            const dropdownsValues = action.source_dropdowns.map((value) => {
              if (Array.isArray(value)) {
                return value
                  .map((id) => {
                    const selectedOptions = dropdownSelectionData[id];
                    return selectedOptions ? selectedOptions[0]?.value : null;
                  })
                  .join(",");
              } else {
                const selectedOptions = dropdownSelectionData[value];
                return selectedOptions ? selectedOptions[0]?.value : null;
              }
            });
            output = dropdownsValues;
          }
          if (action?.source_textFields && formEditedValues) {
            const textFieldsValues = action.source_textFields.map(
              (name) => formEditedValues[name]
            );
            output = output.concat(textFieldsValues);
          }
          const gridApi = gridRefs[action.parent_table_key].current.api;
          if (output && output.length > 0) {
            const selectedRowIndexes = gridApi
              .getSelectedNodes()
              .map(({ rowIndex }) => rowIndex);
            const allRows = [...tableInfo[action.parent_table_key]];
            selectedRowIndexes.forEach((index) => {
              const row = allRows[index];
              action.dest_fields.forEach((field, i) => {
                if (output[i]) row[field] = output[i];
              });
            });
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: [...allRows] },
            });
          }
          if (!action?.preserveSelections) {
            gridApi.forEachNode((node) => node.setSelected(false));
          }
        }
        break;
      }
      case "click": {
        let params = {};

        params[`${action.key}`] = dependentInfo[`${action.key}`]
          ? !dependentInfo[`${action.key}`]
          : true;
        if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
          params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
            ? !dependentInfo[`${action.otherKey}`]
            : true;
        }
        if (action.hasOwnProperty("defaultToggle")) {
          params[`${action.key}`] = action.defaultToggle;
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
        break;
      }
      case "ENABLE_DISABLE_BUTTON_KEYS": {
        let params = {};
        params[action.key] = true;
        params[action.key1] = action.key1 ? true : null;
        params[action.key2] = action.key2 ? true : null;
        params[action.key3] = action.key3 ? true : null;
        params[action.key4] = action.key4 ? true : null;
        params[action.key5] = action.key5 ? true : null;
        params[action.key6] = action.key6 ? true : null;
        params[action.otherKey1] = action.otherKey1 ? false : null;
        params[action.otherKey2] = action.otherKey2 ? false : null;
        params[action.otherKey3] = action.otherKey3 ? false : null;
        params[action.otherKey4] = action.otherKey4 ? false : null;
        params[action.otherKey5] = action.otherKey5 ? false : null;
        params[action.otherKey6] = action.otherKey6 ? false : null;
        params[action.otherKey7] = action.otherKey7 ? false : null;
        params[action.otherKey8] = action.otherKey8 ? false : null;
        params[action.otherKey9] = action.otherKey9 ? false : null;
        params[action.otherKey10] = action.otherKey10 ? false : null;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      case "RESET_TABLE": {
        const gridRef = gridRefs[action.parent_table_key].current;
        gridRef.api.forEachNode((node) => node.setSelected(false));
        dispatch({
          type: "EDIT_ACTION",
          payload: { [action.parent_table_key]: false },
        });
        break;
      }
      case "SET_KEYS": {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: action.data,
        });
        if (action.path) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }
        break;
      }
      case "COPY_COLUMNS_DATA": {
        //copy columns data from one table to other table (from selected rows or whole table)
        //Both tables needs 'id' column to make it work
        const selectedRows = action?.limitToSelections
          ? selectedRowInfo[action.source_table_key]
          : tableInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          const idSet = {};
          selectedRows.forEach((row) => (idSet[row.id] = row));
          const rows = [...tableInfo[action.dest_table_key]];
          rows.forEach((row) => {
            if (idSet.hasOwnProperty(row.id)) {
              action.dest_fields.forEach(
                (field, index) =>
                  (row[field] = idSet[row.id][action.source_fields[index]])
              );
            }
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: rows },
          });
        }
        break;
      }
      case "CLOSE_PARENT_MODAL": {
        modalRefs[action?.parent_modal_key].current.close();
        break;
      }
      case "ADD_ROWS_TO_BUFFER": {
        if (selectedRowInfo[action.selectionSource]) {
          const selectionSet = new Set(
            selectedRowInfo[action.selectionSource].map((row) => row.id)
          );
          const rows = tableInfo[action.sourceTableKey].filter((row) =>
            selectionSet.has(row.id)
          );
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.destTableKey]: [...rows] },
          });
        }
        break;
      }
      case "MULTIPLY_BY_CELLS": {
        const multiplier =
          (dynamicPayload && Number(dynamicPayload)) || action?.multiplier;
        if (typeof multiplier === "number") {
          const targetTable = [...tableInfo[action.targetTableKey]];
          const sourceTable = tableInfo[action.srcTableKey];
          const rowSet = new Set(action.targetRows);
          const srcData = {};
          sourceTable.forEach((row) => {
            if (rowSet.has(row.id)) srcData[row.id] = row;
          });
          targetTable.forEach((row) => {
            if (rowSet.has(row.id)) {
              action.targetFields.forEach((field) => {
                const srcValue = parseFloat(
                  srcData[row.id][field].replace(/,|%/g, "")
                );
                if (!Number.isNaN(srcValue)) {
                  row[field] = formatNumber(Math.trunc(srcValue * multiplier));
                }
              });
            }
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.targetTableKey]: targetTable },
          });
          break;
        }
      }

      case "UPDATE_WARNING_ICON": {
        const targetTableInfo = [...tableInfo[action.parent_table_key]];
        let targetRow = {};
        for (let i = 0; i < targetTableInfo.length; i++) {
          if (targetTableInfo[i].id === dynamicPayload[0]) {
            targetRow = { ...targetTableInfo[i] };
          }
        }
        let childRowIds = targetRow?.child_row_id;
        let childFinalisedDateMatchFlag = true;
        let childrenRows = [];
        targetTableInfo.map((row) => {
          if (childRowIds.includes(row.id)) {
            childrenRows.push(row);
          }
        });

        for (let i = 0; i < childrenRows.length; i++) {
          if (
            childrenRows[i].finalised_clearence_date !== selectedValue &&
            childrenRows[i].id !== targetRow.id
          ) {
            childFinalisedDateMatchFlag = false;
          }
        }

        if (childFinalisedDateMatchFlag) {
          targetTableInfo.map((row, index) => {
            if (childRowIds.includes(row["id"])) {
              row["#add_icon_parent_style_id"] = "";
              row["clearence_indicator"] = "LOW,green";
              targetTableInfo[index] = row;
            }
          });
        }
        if (!childFinalisedDateMatchFlag) {
          targetTableInfo.map((row, index) => {
            if (childRowIds.includes(row["id"])) {
              row["#add_icon_parent_style_id"] = "Y";
              row["clearence_indicator"] = "URGENT,red";
              targetTableInfo[index] = row;
            }
          });
        }

        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parent_table_key]: targetTableInfo },
        });
      }
      case "CLEAR_BUFFER": {
        const obj = {};
        action.keys.forEach((key) => (obj[key] = []));
        dispatch({
          type: "ADD_TO_BUFFER",
          payload: obj,
        });
        break;
      }
      case "CELL_COMPUTE": {
        const sourceRows = tableInfo[action.sourceTableKey];
        const destRows = [...tableInfo[action.destTableKey]];
        if (sourceRows && destRows) {
          const targetRow = destRows[0];
          if (action.hasOwnProperty("targetRowId")) {
            targetRow = destRows.find((row) => row.id == action.targetRowId);
          }
          if (targetRow) {
            Object.keys(action.columns).forEach((sourceField) => {
              const destField = action.columns[sourceField];
              let sum = 0;
              sourceRows.forEach((row) => {
                const v =
                  typeof row[sourceField] == "number"
                    ? row[sourceField]
                    : Number(row[sourceField].replace(/,|%|\$|€/g, ""));
                sum += v;
              });
              targetRow[destField] = sum;
            });

            const { allocated_qty, allocated_qty_default } = destRows;
            let diff = allocated_qty_default - allocated_qty;
            let factor = diff / 6;

            targetRow.qty_6B = targetRow.qty_6B + factor;
            targetRow.qty_7B = targetRow.qty_7B + factor;
            targetRow.qty_8B = targetRow.qty_8B + factor;
            targetRow.qty_9B = targetRow.qty_9B + factor;
            targetRow.qty_10B = targetRow.qty_10B + factor;
            targetRow.qty_11B = targetRow.qty_11B + factor;
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.destTableKey]: destRows },
            });
          }
        }
        break;
      }
      case "SET_COLUMNS_VISIBLE": {
        action.tables.forEach((key) => {
          const gridRef = gridRefs[key]?.current;
          if (gridRef) {
            if (action?.columnsToHide) {
              gridRef.columnApi.setColumnsVisible(action.columnsToHide, false);
            }
            if (action?.columnsToShow) {
              gridRef.columnApi.setColumnsVisible(action.columnsToShow, true);
            }
          }
        });
        break;
      }
      case "COPY_ROW": {
        const tableData = tableInfo[`${action.parent_table_key}`];
        if (tableData && tableData.length > 0) {
          const rows = selectedRowInfo[action.parent_table_key];
          if (rows && rows.length > 0) {
            if (action?.copyFromHidden) {
              let selectedRows = rows.map((row) => row["#id"]);
              if (selectedRows && selectedRows.length > 0) {
                selectedRows = new Set(selectedRows);
                const newTableData = [];
                let index = tableData.length;
                tableData.forEach((row) => {
                  if (selectedRows.has(row.id)) {
                    const rowCopy = { ...row };
                    rowCopy.id = ++index;
                    if (action?.updateFields) {
                      Object.keys(action.updateFields).forEach(
                        (key) => (rowCopy[key] = action.updateFields[key])
                      );
                    }
                    newTableData.push(rowCopy);
                    //pushing duplicated row with new id & editable flag
                  }
                  newTableData.push(row);
                });
                console.log(newTableData);
                const params = {};
                params[`${action.parent_table_key}`] = newTableData;
                dispatch({
                  type: "TABLE_DATA",
                  payload: params,
                });
              }
            } else {
              let selectedRows = rows.map((row) => row["id"]);
              if (selectedRows && selectedRows.length > 0) {
                selectedRows = new Set(selectedRows);
                const newTableData = [];
                let index = 0;
                tableData.forEach((row) => {
                  const rowCopy = { ...row };
                  row.id = index++;
                  newTableData.push(row);
                  if (selectedRows.has(rowCopy.id)) {
                    rowCopy.id = index++;
                    if (action?.updateFields) {
                      Object.keys(action.updateFields).forEach(
                        (key) => (rowCopy[key] = action.updateFields[key])
                      );
                    }
                    newTableData.push(rowCopy); //pushing duplicated row with new id & editable flag
                  }
                });
                const params = {};
                params[`${action.parent_table_key}`] = newTableData;
                dispatch({
                  type: "TABLE_DATA",
                  payload: params,
                });
              }
            }
          }
        }
        break;
      }
      case "ADD_ROW_TO_GROUP": {
        const tableData = tableInfo[`${action.parent_table_key}`];
        if (tableData && tableData.length > 0) {
          let rows = [];
          if (dynamicPayload) {
            if (action.payloadType === "row") {
              rows = [dynamicPayload];
            }
          } else {
            const gridApi = gridRefs[action.parent_table_key].current.api;
            gridApi.forEachNode((node) => {
              if (node.group && node.selected) {
                const lastChildIndex = node.allLeafChildren.length - 1;
                const lastChildRow = node.allLeafChildren[lastChildIndex].data;
                rows.push(lastChildRow);
              }
            });
          }
          if (rows && rows.length > 0) {
            let selectedRows = rows.map((row) => row["id"]);
            if (selectedRows && selectedRows.length > 0) {
              selectedRows = new Set(selectedRows);
              const newTableData = [];
              let index = 0;
              tableData.forEach((row) => {
                const rowCopy = { ...row };
                row.id = index++;
                newTableData.push(row);
                if (selectedRows.has(rowCopy.id)) {
                  rowCopy.id = index++;
                  if (action?.updateFields) {
                    Object.keys(action.updateFields).forEach(
                      (key) => (rowCopy[key] = action.updateFields[key])
                    );
                  }
                  newTableData.push(rowCopy); //pushing duplicated row with new id & editable flag
                }
              });
              const params = {};
              params[`${action.parent_table_key}`] = newTableData;
              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
            }
          }
        }
        break;
      }
      case "APPEND_COLUMN_DEFS":
        const key = action.tableKey;
        const params = {};
        if (Array.isArray(key)) {
          key.forEach((k) => {
            params[k] = action.defs;
          });
        } else {
          params[key] = action.defs;
        }
        {
          dispatch({
            type: "ADD_TABLE_DEFS",
            payload: params,
          });
        }
        break;
      case "REDIRECT_EXTERNAL_LINK":
        {
          window.open(action.url);
        }
        break;
      case "TAB_REDIRECT":
        {
          dispatch({
            type: "TAB_REDIRECT",
            payload: {
              activeTabIndex: action.activeTabIndex,
              parentTabIndex: action.parentTabIndex,
              activeTabValue: action.activeTabValue,
              parentTabValue: action.parentTabValue,
            },
          });
        }
        break;
      case "SET_ROWS_DATA_BY_COLUMN_VALUE":
        {
          function setNodeValue(node, action) {
            const data = node.group ? node.allLeafChildren[0].data : node.data;
            if (data) {
              let { compareValue, outputValues } = action.condition;
              const cellValue = cellValueToNumber(data[action.sourceColumn]);
              if (typeof compareValue === "string") {
                compareValue = cellValueToNumber(data[compareValue]);
              }
              const value =
                cellValue < compareValue
                  ? outputValues[0]
                  : cellValue > compareValue
                  ? outputValues[2]
                  : outputValues[1];
              action.targetColumns.forEach((field) => {
                data[field] = value;
              });
            }
          }
          if (dynamicPayload) {
            if (action.payloadType === "row") {
              setNodeValue(dynamicPayload, action);
              const gridApi = gridRefs[action.tableKey]?.current?.api;
              if (gridApi) {
                setTimeout(() => {
                  gridApi.refreshCells(
                    {
                      rowNodes: [dynamicPayload],
                      force: true,
                      suppressFlash: true,
                    },
                    0
                  );
                });
              }
            }
          } else {
            const gridApi = gridRefs[action.tableKey]?.current?.api;
            if (gridApi) {
              gridApi.forEachNode((node) => {
                setNodeValue(node, action);
              });
              setTimeout(() => {
                gridApi.refreshCells({
                  force: true,
                  suppressFlash: true,
                });
              }, 0);
            }
          }
        }
        break;
      case "NAVIGATE":
        {
          const path = dynamicPayload || action.path;
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              path
          );
        }
        break;
      case "CALCULATE": {
        const params = dynamicPayload;
        const gridApi = params.api;
        if (gridApi) {
          const funcs = {
            "+": (a, b) => a + b,
            "-": (a, b) => a - b,
            "*": (a, b) => a * b,
            "/": (a, b) => a / b,
            "%": (a, b) => a * (b / 100.0),
            "<<": (a, b) => b || a,
          };
          const parseArg = (arg, node) => {
            if (typeof arg === "number") {
              return arg;
            } else {
              let v = 0;
              if (arg.charAt(0) === "#") {
                //pick agg data
                v = node?.parent?.aggData?.[arg.slice(1)] || 0;
              } else {
                v = node?.data?.[arg] || 0;
              }
              if (typeof v === "number") return v;
              v = v.replace(/,|%/g, "");
              return +v;
            }
          };
          const { seq } = action;
          gridApi.forEachNode((node) => {
            if (!node.group) {
              let result = parseArg(seq[0], node);
              for (let i = 2, j = 1; i < seq.length; i += 2, j += 2) {
                result = funcs[seq[j]](result, parseArg(seq[i], node));
              }
              const v = !isFinite(result) ? "0" : formatNumber(result, "float");
              node.data[action.field] = v;
            }
          });
          setTimeout(() => {
            gridApi.refreshCells({
              force: true,
            });
            gridApi.refreshClientSideRowModel("aggregate");
          }, 0);
        }
        break;
      }
      case "REVERT_CELL_VALUE":
        {
          const params = paramsRefs[action.parentTableKey];
          if (params) {
            params.node.setDataValue(params.column.getColId(), params.oldValue);
          }
        }
        break;
      case "SCROLL_TO_TOP":
        {
          window.scrollTo(0, 0);
        }
        break;
      case "DUPLICATE_ROW":
        const gridApi = gridRefs[action.parentTableKey].current.api;
        const rowData = [...tableInfo[action.parentTableKey]];
        let selectedRows = gridApi.getSelectedRows();
        if (action.payloadType === "row") {
          selectedRows = [dynamicPayload];
        }
        if (selectedRows.length === 0) return;
        const selectedRow = rowData.find(
          (row) => row["id"] === selectedRows[0]["id"]
        );
        const newRow = { ...selectedRow, ...action.columnData };
        newRow.id = rowData.length + 1;
        const selectedIndex = rowData.findIndex((row) => row === selectedRow);
        rowData.splice(selectedIndex + 1, 0, newRow);
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parentTableKey]: rowData },
        });
        break;
      case "FILL_ROWDATA_BY_MAPPED_COLUMNS":
        const node = dynamicPayload;
        if (node) {
          const rows = tableInfo[action.mappedTableKey];
          let mappedRow = null;
          for (let i = 0; i < rows.length; i++) {
            const match = action.mappedColumns.every(
              (col) => node.data[col] === rows[i][col]
            );
            if (match) {
              mappedRow = rows[i];
              break;
            }
          }
          if (mappedRow) {
            const rows = [...tableInfo[action.parentTableKey]];
            const targetRow = rows.find((row) => row.id === node.data["id"]);
            Object.keys(mappedRow).forEach((key) => {
              targetRow[key] = mappedRow[key];
            });
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parentTableKey]: rows },
            });
          }
        }
        break;
      case "UPDATE_TABLE_DATA":
        {
          const sourceData = tableInfo[action?.source_table_key];
          dispatch({
            type: "TABLE_DATA",
            payload: {
              [action?.parent_table_key]: sourceData,
            },
          });
        }
        break;
      default:
        actionOccurred = false;
    }
    return actionOccurred;
  }
  function invokeAction(action, dynamicPayload = null, selectedValue) {
    if (action && Array.isArray(action)) {
      action.forEach((obj) => onAction(obj, dynamicPayload));
    } else {
      onAction(action, dynamicPayload, selectedValue);
    }
  }
  return invokeAction;
}
