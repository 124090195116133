import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAction from "../../../utils/useAction";
import Select from "react-select";
import Tippy from "@tippyjs/react";
const customStyles = ({ controlWidth, menuWidth }) => ({
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "black" : provided.borderColor,
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    height: "25px",
    width: controlWidth || "120px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    width: menuWidth || "150px",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "0.75rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
});
const getOptions = (optionsStr, descStr) => {
  const options = optionsStr.trim().split(",");
  const desc = descStr.trim().split(",");
  return options.map((str, i) => {
    const value = str.trim();
    return {
      value,
      label: value,
      tooltip: desc[i] || "",
    };
  });
};
export function CellDropdown(props, rowData) {
  const [flagEffect] = useState(() =>
    props.hasOwnProperty("flagEffect") ? props.flagEffect : true
  );
  const dispatch = useDispatch();
  const { tableInfo } = useSelector((state) => state.home);
  const invokeAction = useAction();
  const [dropdowns] = useState(() => {
    let containers = rowData[`${props?.colDef?.field}#options`];
    let descriptions = rowData[`${props?.colDef?.field}#options_desc`] || "";
    if (!containers) return [];
    containers = containers.trim()?.split(":");
    descriptions = descriptions.trim()?.split(":");
    return containers.map((optionsStr, i) =>
      getOptions(optionsStr, descriptions[i] || "")
    );
  });

  const [selected, setSelected] = useState([]);
  const handleAction = (
    selectedValue,
    index,
    actions,
    defaultAction,
    rowId
  ) => {
    const optionIndex = dropdowns[index].findIndex(
      (option) => selectedValue === option.value
    );
    if (props?.idBasedAction) {
      if (optionIndex !== -1 && rowId < actions.length) {
        const actionToInvoke = actions[rowId];
        invokeAction(actionToInvoke, [rowId], selectedValue);
      }
    } else {
      if (optionIndex !== -1 && optionIndex < actions.length) {
        if (actions[optionIndex] === "default") {
          invokeAction(defaultAction, [rowId], selectedValue);
        } else {
          invokeAction(actions[optionIndex], [rowId], selectedValue);
        }
      }
    }
  };
  const handleChange = useCallback(
    (newOption, index, params, rowData) => {
      const arr = [...selected];
      arr[index] = newOption;
      const rows = [...tableInfo[params.parent_table_key]];
      let row = null;
      if (rowData.hasOwnProperty("id")) {
        row = rows.find((row) => row.id === rowData.id);
      } else {
        row = rows[params.rowIndex];
      }
      row[params.colDef.field] = arr.map((obj) => obj.label).join(",");

      dispatch({
        type: "TABLE_DATA",
        payload: { [params.parent_table_key]: [...rows] },
      });
      params.api.stopEditing();
      if (params.actions) {
        handleAction(
          newOption.value,
          index,
          params.actions,
          params?.defaultAction,
          row["id"] || params.rowIndex
        );
      } else if (params.action) {
        invokeAction(params.action, params.node);
      }
    },
    [selected, tableInfo]
  );

  const selects = useMemo(
    () =>
      dropdowns.map((options, index) => {
        if (flagEffect && rowData.hasOwnProperty("#disable_dropdown")) {
          if (rowData["#disable_dropdown"] === "Y") {
            return props?.hideLabel
              ? ""
              : (selected.length > 0 && selected[index]?.label) ||
                  options[0]?.label;
          }
        }
        return (
          <Select
            options={options}
            value={selected[index] || options[0]}
            onChange={(value) => handleChange(value, index, props, rowData)}
            styles={customStyles(props)}
            menuShouldBlockScroll={true}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isSearchable={false}
            maxMenuHeight={200}
            components={{ Option: CustomOption }}
          />
        );
      }),
    [selected, dropdowns, props, rowData]
  );

  useEffect(() => {
    const labels = rowData[props.colDef.field]?.trim().split(",");
    setSelected((prev) => {
      return dropdowns.map((options, index) => {
        const option = options.find(
          ({ label }) => label === labels[index].trim()
        );
        return option || prev[index];
      });
    });
  }, [props]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "5px",
        position: "relative",
      }}
    >
      {selects}
    </div>
  );
}
export default function Renderer(props) {
  const rowData = props.node.group
    ? props.node.allLeafChildren[0].data
    : props.data;
  const [hiddens, setHiddens] = useState(() => {
    if (rowData?.hasOwnProperty("#hide_dropdown_multi")) {
      const fields = rowData["#hide_dropdown_multi"];
      if (fields) {
        const set = new Set(fields.split(","));
        return set;
      }
      return null;
    }
    return null;
  });
  if (hiddens && hiddens.has(props.colDef.field)) {
    return "";
  }
  if (props.node.group) {
    return props.renderForGroup ? CellDropdown(props, rowData) : "";
  }
  return CellDropdown(props, rowData);
}

function CustomOption({
  innerProps,
  label,
  data,
  isDisabled,
  isFocused,
  isSelected,
}) {
  return (
    <Tippy
		  content={data.tooltip}
		  delay={[200, 0]}
		  arrow={false}
		  placement="right-start"
		  className="custom-tippy"
    >
      <div
        {...innerProps}
        style={{
          padding: "8px 12px",
          backgroundColor: isSelected
            ? "#197eff"
            : isFocused
            ? "#deebff"
            : "transparent",
          color: isSelected ? "#fff" : "#333",
          fontSize: "0.79rem",
        }}
      >
        {label}
      </div>
    </Tippy>
  );
}
